// Basics
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// Utilities
import { get } from 'lodash';

// Views
import Layout from '../components/Layout/Layout';
 
// Libraries
import { PageTemplate } from '../libraries/Parser';

const Page = ({ data }) => {
  const { wordpressPage: page, allWordpressAcfOptions: optionsPage } = data

  return (
    <Layout
      title={page.title}
      isHomePage={page.wordpress_id === 8}
      seo={get(page, 'yoast_meta')}
    >
      <div className="page-content">
        {/* <AtomPageHead /> */}
        <PageTemplate
          key={page.wordpress_id}
          title={page.title}
          content={page.content}
          acf={page.acf}
          optionsPage={optionsPage}
        />

      </div>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    allWordpressAcfOptions {
      edges {
        node{
          id
          options {
            global_templates_our_project_configurations {
              projects_list {
                name
                image {
                  localFile {
                    childImageSharp {
                      fluid (maxWidth: 3080, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  source_url
                }
                categories
                url {
                  title
                  url
                  target
                }
              }
            }
            global_templates_work_location_configurations {
              work_locations_list {
                enable_map
                latitude
                longitude
                name
                section_rows {
                  address
                  email
                  is_phone_number_available
                  phone_number
                  phone_number_text
                  section_heading
                }
              }
            }
            global_templates_get_in_touch_configurations {
              profiles_list {
                section_title
                section_key
                link_title
                link_url
              }
            }
            global_templates_testimonial_configurations {
              testimonials_list {
                name
                designation
                avatar
                content
              }
            }
            global_templates_website_configuration_project_settings {
              categories {
                label
                value
              }
            }
          }
        }
      }
    }      
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
      acf {
        all_available_sections_page {
          __typename
          ... on WordPressAcf_get_in_touch {
            section_key
          }
          ... on WordPressAcf_full_section_groups {
            groups {
              section_title
              section_subtitle
              section_background_type
              section_background_color
              section_background_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3080, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                source_url
              }
              section_links {
                link
              }
              css_title_font_color
              css_subtitle_font_color
            }
          }
          ... on WordPressAcf_collapsible_accordion {
            section_content {
              section_rows {
                section_heading
                section_sub_heading
                collapsed_content {
                  section_content
                  section_heading
                }
              }
            }
            section_title
          }
          ... on WordPressAcf_other_services {
            id
            section_content {
              section_rows {
                section_heading
                section_icon
                section_sub_heading
              }
            }
            section_heading
            section_sub_heading
          }
          ... on WordPressAcf_our_people {
            id
            section_sub_heading
            section_heading
            our_people_content {
              our_people_rows {
                designation
                our_people_heading
                our_people_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 3080, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  source_url
                }
                our_people_social_networks {
                  platform_icon
                  platform_url
                }
              }
            }
          }

        
        ... on WordPressAcf_textual_section {
          id
          textual_content {
            description
            title
          }
        }
     
     
        ... on WordPressAcf_video_section {
          id
          video_file {
            localFile {
              url
              publicURL
            }
          }
          video_poster {
            localFile {
              publicURL
            }
          }
          video_type
          video_url
        }
     
          ... on WordPressAcf_slider_section {
            id
            slider_builder {
              section_content
              section_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3080, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                    fixed(quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              section_type
              section_title
            }
          }

          ... on WordPressAcf_image_single_section {
            id
            section_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 3080, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
     
          ... on WordPressAcf_work_location_listing {
            id
            show_work_location
          }
          ... on WordPressAcf_testimonial_listing {
            id
            show_testimonials
          }
          ... on WordPressAcf_project_listing {
            id
            show_projects
          }
        }
      }
    }
  }
`;
