// Basics
import React from 'react';
import './index.scss';

// Utilities
import { map, size } from 'lodash';
import PropTypes from 'prop-types';

// Views
import AtomCollapsibleContent from "../CollapsibleContent";
import AtomGridContainer from "../GridContainer";

class AtomCollapsibleDetailContent extends React.Component {

  render() {
    const { informationcontainerList } = this.props;

    // If list is empty
    if (size(informationcontainerList) < 1) {
      return null;
    }

    return (
      <div className="atom-collapsible-detail-content">
        {
          map(informationcontainerList, function (row, index) {
            const { title, description, listGroup } = row;
            return (
              <div className="collapsible-detail-content-list" key={index}>
                <div className="header">
                  {
                    title &&
                    <h2>{title}</h2>
                  }
                  {
                    description &&
                    <p>{description}</p>
                  }
                </div>
                <AtomCollapsibleContent>
                  <AtomGridContainer
                    list={listGroup}
                  />
                </AtomCollapsibleContent>
              </div>
            )
          })
        }

      </div>
    )
  }
}

AtomCollapsibleDetailContent.propTypes = {
  informationcontainerList: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      listGroup: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired
    }),
  ).isRequired
};

export default AtomCollapsibleDetailContent;

