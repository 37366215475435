// Basics
import React from 'react'
import './index.scss';
import { map, size } from 'lodash';
import PropTypes from 'prop-types';

import AtomGridContainer from "../GridContainer";
import AtomGoogleMap from '../GoogleMap';

class AtomContactContent extends React.Component {
   
  render() {
    const { items } = this.props;

    // If list is empty
    if (size(items) < 1) {
      return null;
    }

    return (
      <div className="atom-contact-content">
        {
          map(items, function (row, index) {
            const {
              title,
              listGroup,
              mapLatitude,
              mapLongitude,
              showMap
            } = row;

            return (
              <div className="contact-content" key={index}>
                {
                  title &&
                  <h2>{title}</h2>
                }

                <AtomGridContainer
                  list={listGroup}
                />
                { showMap && (
                  <div className="map">
                    <AtomGoogleMap
                      lat={mapLatitude}
                      lng={mapLongitude}
                    />
                  </div>
                )}
              </div>
            )
          })
        }
      </div>
    )
  }
}

AtomContactContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      listGroup: PropTypes.array.isRequired,
      mapLatitude: PropTypes.number,
      mapLongitude: PropTypes.number,
      showMap: PropTypes.bool
    }),
  ).isRequired
};

export default AtomContactContent;

