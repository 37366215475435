// Basics
import React from 'react'
import './index.scss';
import { map, size } from 'lodash';
import PropTypes from 'prop-types';
import AtomSection from '../Section';

class AtomOtherServices extends React.Component {

  render() {

    const { description, list, title } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="atom-other-services">
        <AtomSection
          title={title}
          description={description}
          content={(
            <div className="columns">
              {
                map(list, function (row, index) {
                  const { content, icon } = row;
                  return (
                    <div
                      className="column services-column"
                      key={index}
                    >
                      {
                        icon &&
                        <span className={`fa fa-${icon}`} />

                      }
                      {
                        row.title &&
                        <h5>{row.title}</h5>
                      }
                      {
                        content &&
                        <p>{content}</p>
                      }

                    </div>
                  )
                })
              }
            </div>
          )}
        />
      </div>
    )
  }
}

AtomOtherServices.propTypes = {
  description: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  title: PropTypes.string.isRequired,
}

export default AtomOtherServices;

