// Basics
import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

// Render view
const renderView = (content) => {
  return (
    <div className="atom-image-background">
      {content}
    </div>
  );
}

const AtomImageBackground = ({ backgroundColor, className, source, type }) => {

  if (type === 'fluid') {
    return renderView(
      <BackgroundImage
        backgroundColor={backgroundColor}
        className={className}
        fluid={source}
      />
    );
  }
  
  return renderView(
    <BackgroundImage
      backgroundColor={backgroundColor}
      className={className}
      fixed={source}
    />
  );
}

AtomImageBackground.defaultProps = {
  backgroundColor: null,
  className: null,
};

AtomImageBackground.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  source: PropTypes.object,
  type: PropTypes.oneOf(['fluid', 'fixed']).isRequired
};

export default AtomImageBackground;
