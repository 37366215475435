/* eslint-disable react/default-props-match-prop-types */
// Basics
import React from 'react'
import PropTypes from 'prop-types';
import './index.scss';

import { Link } from 'gatsby';

// Assets
import arrow from '../../../img/arrow-white.svg';

class AtomPreFooter extends React.Component {
  render() {
    const { title, linkTitle, linkUrl } = this.props;

    return (
      <div className="atom-pre-footer">
        <div className="container-fluid">
          {
            title &&
            <h2>{title}</h2>
          }
          <Link to={linkUrl} className="arrow-link">
            <img src={arrow} alt="Arrow" className="arrow" />
            {linkTitle}
          </Link>
        </div>
       </div>
    )
  }
}

AtomPreFooter.defaultProps = {
  title: "Let's talk about you",
  linkTitle: "Get in touch",
};

AtomPreFooter.propTypes = {
  title: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export default AtomPreFooter;

