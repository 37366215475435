// Basics
import React from 'react'
import './index.scss';
import PropType from 'prop-types';
// Utilities
import {
  map,
  size
} from 'lodash';


class AtomTextualContent extends React.Component {

  render() {
    const { list } = this.props;
    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="atom-textual-content">
        <div className="container">
          {
            map(list, function (row, index) {
              const { title, description } = row;
              return (
                <div className="textual-content-row" key={index}>
                  {
                    title &&
                    <h2>{title}</h2>
                  }
                  {
                    description &&
                    <p>{description}</p>

                  }
                </div>
              )
            })
          }
        </div>
       </div>

    )
  }
}

AtomTextualContent.defaultProps = {
  list: [
    {
      title: "Reimagining communications once again",
      description: "Vonage is a well-known brand with a proven legacy. Loved for its offbeat tone that permeated the telecom space in the early 2000s, Vonage made a name on its ability to reinvent the way people communicate using technology. But with the decline of the residential home phone market, Vonage dared to rethink the future of its business. Never one to sit still, Vonage evolved through a series of mergers and acquisitions that allowed it to move beyond old telecomm, into software as a service and from residential customers to businesses – a seismic shift that required an equally powerful brand evolution."
    },
    {
      title: "Surfacing a superpower",
      description: "We worked closely with Vonage to create a unified identity that brought together the best of its heritage with the innovation it’s known for today. By building on their purpose of leading through change, we created a visual language that could flex across the tech space and into the hearts and minds of developers. Approaching everything through a system of extrusion, the system shows how Vonage can be there when you need it, and fade away when you don't. Rich gradients challenge the more static colorspace of the industry, and the illustration style and verbal identity brings to life the quirk and irreverence that made Vonage memorable in the past. All of it works together to create an experience that’s as delightful as it is helpful."
    }
  ]
}

AtomTextualContent.propTypes = {
  list: PropType.arrayOf(PropType.shape({
    title: PropType.string.isRequired,
    description: PropType.string.isRequired
  })).isRequired,
};
export default AtomTextualContent;

