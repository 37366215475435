// Basics
import React from 'react'
import './index.scss';

import PropTypes from 'prop-types';

class AtomCollapsibleContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };

    // Binding
    this.toggleArticle = this.toggleArticle.bind(this);
  }

  toggleArticle() {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded
    })
  }

  render() {
    const { isExpanded } = this.state;
    const {
      children,
      seeLessText,
      seeMoreText
    } = this.props;

    return (
      <div className="atom-collapsible-content">
        <div className="more-content">
          <span className="more" onClick={this.toggleArticle}>
            <span className="underline">
            {isExpanded ? seeLessText : seeMoreText}
            </span>
            {isExpanded ? ' -' : ' +'}
          </span>
        </div>
        <div className={`collapsible-content${isExpanded ? ' show-content' : ''}`}>
          {children}
        </div>
      </div>
    )
  }
}

AtomCollapsibleContent.defaultProps = {
  seeLessText: "See less",
  seeMoreText: "See more",
}

AtomCollapsibleContent.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string
}

export default AtomCollapsibleContent;

