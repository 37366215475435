// Basics
import React from 'react';
import PropType from 'prop-types';
import './index.scss';

// Utilities
import {
  concat,
  get,
  isFunction,
  join,
  map,
  size
} from 'lodash';
   
// Views
import AtomProjectCollection from '../Project/Collection';

// Packages
const Isotope = typeof window !== `undefined` ? require("isotope-layout") : null;

const AtomFilterableList = ({ categories, items, onFilterChange, activeFilter }) => {

  // store the isotope object in one state
  const [isotope, setIsotope] = React.useState(null);
  // store the filter keyword in another state
  const [filterKey, setFilterKey] = React.useState("*");

  // initialize an Isotope object with configs
  React.useEffect(() => {
    setIsotope(
      new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: "fitRows"
      })
    );
  }, []);

  // handling filter key change
  React.useEffect(
    () => {
      if (isotope) {
        filterKey === "*"
          ? isotope.arrange({ filter: `*` })
          : isotope.arrange({ filter: `.${filterKey}` });
      }
    },
    [isotope, filterKey]
  );

  const handleFilterClick = (filter) => {
    setFilterKey(filter);

    // Updating active filter
    if (isFunction(onFilterChange)) {
      onFilterChange(filter);
    }
  }

  // If categories or items not provided
  if (
    (size(categories) < 1)
    || (size(items) < 1)
  ) {
    return null;
  }

  // Complete category list
  const categoryList = concat({
    label: 'All',
    value: '*'
  }, categories);

  return (
    <div className="atom-filterable-list">
      <div className="container">
        <ul className="categories-list">
          {
            map(categoryList, (row, index) => {
              const { value, label } = row;

              // Remove this specific category
              if (value === '*:all') {
                return null;
              }

              return (
                <li
                  className={activeFilter === value ? 'active' : ''}
                  key={index}
                  onClick={() => handleFilterClick(value)}
                >
                  {label}
                </li>
              )
            })
          }
        </ul>
      </div>
       {/* <hr /> */}
      <AtomProjectCollection
        className="filter-container"
        items={items}
      />
    </div>
  );
};

AtomFilterableList.propTypes = {
  activeFilter: PropType.string.isRequired,
  categories: PropType.arrayOf(PropType.shape({
    value: PropType.string.isRequired,
    label: PropType.string.isRequired
  })).isRequired,
  items: PropType.arrayOf(PropType.shape({
    categories: PropType.array.isRequired,
    name: PropType.string.isRequired,
    image: PropType.object.isRequired,
    url: PropType.shape({
      target: PropType.string.isRequired,
      title: PropType.string.isRequired,
      url: PropType.string.isRequired
    }).isRequired,
  })).isRequired,
  onFilterChange: PropType.func.isRequired
};

export default AtomFilterableList;
