// Basics
import React from 'react';
import './index.scss';

// Views
import AtomFilterableList from '../FilterableList';

// Assets
// import nature from '../../../img/nature.png';

class AtomPortfolioFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeFilter: '*'
    };

    // Bindings
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(value) {
    this.setState({
      activeFilter: value
    });
  }

  render() {
    const {categories, items} = this.props;
    const {activeFilter} = this.state;

    return (
      <div className="atom-portfolio-filters">
        <AtomFilterableList
          activeFilter={activeFilter}
          categories={categories}
          items={items}
          onFilterChange={this.handleFilterChange}
        />
      </div>
    );
  }
}

export default AtomPortfolioFilters;
