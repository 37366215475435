// Basics
import React from 'react'
import './index.scss';
import AtomSection from '../Section';
import AtomOurPeopleContent from '../OurPeopleContent';
 
class AtomOurPeople extends React.Component {

  render() {
    const {title, description, list } = this.props;

    return (
      <div className="atom-our-people">
        <AtomSection
          title={title}
          description={description}
          content={(
            <AtomOurPeopleContent
              list={list}
            />
          )}
        />
      </div>
    )
  }
}
 
export default AtomOurPeople;

