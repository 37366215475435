// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// Package
import GoogleMapReact from 'google-map-react';

// Images
import IconMapPin from '../../../img/map-pin.svg';

// Google map key
const GoogleMapKey = 'AIzaSyCcXbyjPBTr-zm7ZVBcKV-SFJrfOZdJPRU';

// Map pin marker
const MapPinComponent = (text) => (
    <div className="google-map-pin-container">
        <img
            className="google-map-pin-icon"
            src={IconMapPin}
            alt="Location pin"
        />
    </div>
);

// Map styles (not in use)
// const MapStyles = [
//     {
//         featureType: "poi",
//         elementType: "labels",
//         stylers: [
//             { visibility: "off" }
//         ]
//     }
// ];

// Map config for initialization
const CreateMapOptions = (maps) => {
    return {
        fullscreenControl: false,
        mapTypeControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
        // style: MapStyles,
    }
};

class AtomGoogleMap extends React.Component {

    render() {
        const { lat, lng, zoom } = this.props;

        return (
            <div className="atom-google-map" style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GoogleMapKey }}
                    defaultCenter={{
                        lat: lat,
                        lng: lng,
                    }}
                    defaultZoom={zoom}
                    options={CreateMapOptions}
                >
                    <MapPinComponent
                        lat={lat}
                        lng={lng}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

AtomGoogleMap.defaultProps = {
    zoom: 15,
};

AtomGoogleMap.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    zoom: PropTypes.number,
};

export default AtomGoogleMap;
