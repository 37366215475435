// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

class AtomTestimonialItem extends React.Component {

  render() {
    const { content, name, designation} = this.props;

    return (
      <div
        className="atom-testimonial-item"
      >
        {
          content &&
          <h2>{content}</h2>
        }

        <span className="about-quote-credits">
          {
            name &&
            <h3>{name}</h3>
          }

          {
            designation &&
            <p>{designation}</p>
          }
        </span>
      </div>
    )
  }
}

AtomTestimonialItem.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
};

export default AtomTestimonialItem;

