// Basic
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

class HtmlParser extends React.Component {
    render() {
        const { html } = this.props;
        return (
          <div className="html-parser">{ReactHtmlParser(html)}</div>
        );

    }
}
export default HtmlParser;
