// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// Utilities
import { get, map, size } from 'lodash';

// Views
import AtomSlider from '../Slider';
import AtomTestimonialItem from '../TestimonialItem';

class AtomTestimonialContainer extends React.Component {

  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }
    
    // Prepare testimonial list view
    const testimonialList = map(list, function(row, index) {
      
      // Return view
      return (
        <AtomTestimonialItem
          key={index}
          content={get(row, 'content')}
          name={get(row, 'name')}
          designation={get(row, 'designation')}
        />
      );
    });

    return (
      <div className="atom-testimonials">
        <AtomSlider list={testimonialList} />
      </div>
    )
  }
}

AtomTestimonialContainer.defaultProps = {
  list: [
    {
      content: "Wolff Olins took our DNA and developed it into a new and refined brand identity setting us up for the next ten years of growth.",
      name: "Cynthia Round",
      designation: "Senior Vice President Marketing and External Relations, The Metropolitan Museum of Art"
    },
    {
      content: "Wolff Olins took our DNA and developed it into a new and refined brand identity setting us up for the next ten years of growth.",
      name: "Cynthia Round",
      designation: "Senior Vice President Marketing and External Relations, The Metropolitan Museum of Art"
    }
  ]
}

AtomTestimonialContainer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      designation: PropTypes.string.isRequired,
    })
  ).isRequired,
}


export default AtomTestimonialContainer;

