// Basics
import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import { Helmet } from "react-helmet";

// Utilities
import { get, includes, map, size } from 'lodash';

// Views
import Footer from '../../Layout/Footer';
import AtomImageBackground from '../ImageBackground';

// Renderer function for pre and post content
export const RenderPrePostContent = (content) => (
  <div className="section fp-auto-height">
    {content}
  </div>
);

// Class for full page
const hasFullPageLoaded = "full-page-loaded";

class AtomFullSection extends React.Component {

  componentDidMount() {
    document.body.classList.add(hasFullPageLoaded);
  }

  render() {
    const { list, postContent, preContent } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    // First section's background color to use to apply on container until the library has loaded
    const firstSectionBackgroundColor = get(list, [0, 'section_background_color']);

    return (
      <div className="atom-full-section">
        {firstSectionBackgroundColor && (
          <Helmet>
            <style type="text/css">
              {`
                body {
                  background-color: ${firstSectionBackgroundColor};
                }
              `}
            </style>
          </Helmet>
        )}
        <ReactFullpage
          scrollingSpeed={1000}
          scrollOverflow
          scrollOverflowReset
          render={() => {
            return (
              <ReactFullpage.Wrapper>
                {
                  (size(preContent) > 0) &&
                  RenderPrePostContent(preContent)
                }
                {
                  map(list, (row, index) => {

                    // Background image
                    const backgroundType = get(row, 'section_background_type');

                    // Link
                    const sectionLinkOriginal = get(row, 'section_links.0.link');
                    const sectionLink = !includes(['', '#'], sectionLinkOriginal) ? sectionLinkOriginal : null;

                    // style
                    const style = backgroundType === 'color'
                      ? {
                        backgroundColor: row.section_background_color
                      }
                      : (
                        backgroundType === 'image'
                          ? {
                            backgroundImage: `url(${get(row, 'section_background_image.source_url')})`
                          }
                          : null
                      )

                    return (
                      <div
                        key={index}
                        className="section"
                        id={`section${index}`}
                        style={style}
                      >
                        {/* {
                          backgroundType === 'image' && (
                            <AtomImageBackground
                              backgroundColor={row.section_background_color}
                              source={get(row, 'section_background_image.localFile.childImageSharp.fluid')}
                              type="fluid"
                            />
                          )
                        } */}
                        <div className="section-content">
                          <div className="container-fluid">
                            {
                              row.section_title && (
                                <h2 style={{ color: row.css_title_font_color }}>
                                  {
                                    (sectionLink)
                                      ? (
                                        <a
                                          href={sectionLink}
                                          rel="noopener noreferrer"
                                          style={{ color: row.css_title_font_color }}
                                        // target="_blank"
                                        >
                                          {row.section_title}
                                        </a>
                                      )
                                      : row.section_title
                                  }
                                </h2>
                              )
                            }
                            {row.section_subtitle && (
                              <div className="description" style={{ color: row.css_subtitle_font_color }}>
                                <p>{row.section_subtitle}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  (size(postContent) > 0) &&
                  RenderPrePostContent(postContent)
                }
                <Footer isFullSection />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>

    )
  }
}

export default AtomFullSection;

