// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Utilities
import { get, map, size } from 'lodash';

// View
import AtomSlider from '../Slider';
import AtomSlideContentItem from '../SlideContentItem';
import HtmlParser from '../../../libraries/HtmlParser';

// Assets
import fullImage from '../../../img/full-image.jpg';

class AtomSlideContentItemcontainer extends React.Component {

  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    // Prepare testimonial list view
    const SlideContentList = map(list, function (row, index) {

      // Return view
      return (
        <AtomSlideContentItem
          key={index}
          description={<HtmlParser html={get(row, 'section_content')} />}
          image={get(row, 'section_image')}
          layoutType={get(row, 'section_type')}
          title={get(row, 'section_title')}
        />
      );
    });

    return (
      <div className="atom-slide-content-container">
        <AtomSlider
          showArrows={false}
          showIndicators
          list={SlideContentList}
        />
      </div>
    );
  }
}

//left-image, only-image, only-text
AtomSlideContentItemcontainer.defaultProps = {
  list: [
    {
      description: "What matters most is impact – achieving big things for our client, their business and the audiences they serve. How we do it matters too. We always work with thoughtfulness, boldness and care.",
      image: fullImage,
      layoutType: 'right-image',
      title: "With humanity"
    },
    {
      description: "What matters most is impact – achieving big things for our client, their business and the audiences they serve. How we do it matters too. We always work with thoughtfulness, boldness and care.",
      image: fullImage,
      layoutType: 'left-image',
      title: "With humanity"
    },
    {
      description: "What matters most is impact – achieving big things for our client, their business and the audiences they serve. How we do it matters too. We always work with thoughtfulness, boldness and care.",
      image: fullImage,
      layoutType: 'only-image',
      title: "With humanity"
    },
    {
      description: "What matters most is impact – achieving big things for our client, their business and the audiences they serve. How we do it matters too. We always work with thoughtfulness, boldness and care.",
      image: fullImage,
      layoutType: 'only-text',
      title: "With humanity"
    },

  ]
}

AtomSlideContentItemcontainer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.object,
      layoutType: PropTypes.oneOf(['left-image', 'right-image', 'only-image', 'only-text']),
      title: PropTypes.string,
    })
  ).isRequired,
}

export default AtomSlideContentItemcontainer;
