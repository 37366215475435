// Basics
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import {
    concat,
    find,
    get,
    map,
    size,
    takeRightWhile,
    takeWhile,
    toNumber
} from 'lodash';

// Views
import AtomFullSection from '../components/Atoms/FullSection';
import AtomCollapsibleDetailContentContainer from '../components/Atoms/CollapsibleDetailContentContainer';
import AtomPortfolioFilters from '../components/Atoms/PortfolioFilters';
import AtomPreFooter from '../components/Atoms/PreFooter';
import AtomTestimonialContainer from '../components/Atoms/TestimonialContainer';
import AtomContactContainer from '../components/Atoms/ContactContainer';
import AtomOtherServices from '../components/Atoms/OtherServices';
import AtomOurPeople from '../components/Atoms/OurPeople';
import AtomTextualContent from '../components/Atoms/TextualContent';
import AtomSlideContentItemcontainer from '../components/Atoms/SlideContentItemcontainer';
import AtomFullImage from '../components/Atoms/FullImage';
import AtomEmbedVideoContainer from '../components/Atoms/EmbedVideoContainer';
import AtomProjectCollection from '../components/Atoms/Project/Collection';

// Libraries
import { normalizeData } from './Utility';

/**
 * Page content object.
 * @param {string} key 
 * @param {Element} content 
 * @param {any} data 
 * @returns {object}
 */
export const PageContentObject = (key, content, data) => {
    return {
        key,
        content,
        data
    };
};

// Formatted options page data
export const AcfOptionsPageData = (pageContent) => {

    // Get in touch profiles
    const getInTouchProfiles = get(pageContent, 'global_templates_get_in_touch_configurations.profiles_list', null);

    // If profiles are present
    let getInTouchProfilesData = null;
    if (size(getInTouchProfiles) > 0) {

        // Normalize data
        getInTouchProfilesData = normalizeData(getInTouchProfiles, true, 'section_key');
    }

    // Return
    return {
        getInTouchProfiles: getInTouchProfilesData,
        projectList: get(pageContent, 'global_templates_our_project_configurations.projects_list', null),
        projectCategoryList: get(pageContent, 'global_templates_website_configuration_project_settings.categories', null),
        testimonialList: get(pageContent, 'global_templates_testimonial_configurations.testimonials_list', null),
        workLocations: get(pageContent, 'global_templates_work_location_configurations.work_locations_list', null),
    };
};

export const PageTemplate = ({
    // title, content,
    acf, optionsPage
}) => {

    // console.log('acf', acf);

    // ACF content
    const acfContent = get(acf, 'all_available_sections_page');

    // ACF options page content
    const acfOptionsPageContent = AcfOptionsPageData(get(optionsPage, 'edges.0.node.options'));
    // console.log('acfOptionsPageContent', acfOptionsPageContent);

    // If ACF content is not present
    if (!acf || !acfContent) {
        return null;
    }

    // If full-section content is present
    const findFullSectionContent = find(acfContent, { '__typename': 'WordPressAcf_full_section_groups' });

    // Iterate all content
    const allContent = map(acfContent, (row, index) => {

        // Content type name
        const contentType = get(row, '__typename');

        // Default
        let sectionContent = null;
        let sectionData = null;

        // If full section
        if (contentType === 'WordPressAcf_full_section_groups') {
            sectionContent = null;
            sectionData = null;
        }

        // If get in touch section
        if (contentType === 'WordPressAcf_get_in_touch') {

            // Profile key
            const profileKey = row.section_key;
            const profileData = get(acfOptionsPageContent, `getInTouchProfiles.byId.${profileKey}`);

            sectionContent = (
                <AtomPreFooter
                    linkTitle={profileData.link_title}
                    linkUrl={profileData.link_url}
                    key={index}
                    title={profileData.section_title}
                />
            );
        }

        // If other services section
        if (contentType === 'WordPressAcf_other_services') {

            // Default
            let otherServicesList = null;

            // Getting all rows
            const otherServicesRows = get(row, 'section_content.section_rows');
            if (size(otherServicesRows) > 0) {

                // Iterating all rows
                otherServicesList = map(otherServicesRows, function (serviceRow) {
                    return {
                        content: get(serviceRow, 'section_sub_heading'),
                        icon: get(serviceRow, 'section_icon'),
                        title: get(serviceRow, 'section_heading')
                    };
                })
            }

            sectionContent = (
                <AtomOtherServices
                    description={row.section_sub_heading}
                    list={otherServicesList}
                    key={index}
                    title={row.section_heading}
                />
            );
        }

        // If our textual section
        if (contentType === 'WordPressAcf_textual_section') {

            // Default
            let textualList = null;

            // Getting all rows
            const textualRows = get(row, 'textual_content');
            if (size(textualRows) > 0) {

                textualList = map(textualRows, function (textualRow) {
                    return {
                        title: get(textualRow, 'title'),
                        description: get(textualRow, 'description'),
                    }
                });

            }


            sectionContent = (
                <AtomTextualContent
                    list={textualList}
                    key={index}
                />
            );
        }


        // If our people section
        if (contentType === 'WordPressAcf_our_people') {

            // Default
            let otherPeopleList = null;

            // Getting all rows
            const otherPeopleRows = get(row, 'our_people_content.our_people_rows');
            if (size(otherPeopleRows) > 0) {

                // Iterating all rows
                otherPeopleList = map(otherPeopleRows, function (peopleRow) {
                    return {
                        designation: get(peopleRow, 'designation'),
                        title: get(peopleRow, 'our_people_heading'),
                        item: map(get(peopleRow, 'our_people_social_networks'), function (socialiconRow, socialiconIndex) {
                            // Return
                            return {
                                key: socialiconIndex,
                                icon: get(socialiconRow, 'platform_icon'),
                                link: get(socialiconRow, 'platform_url'),
                            };
                        }),
                        image: get(peopleRow, 'our_people_image'),
                    };
                })

            }

            sectionContent = (
                <AtomOurPeople
                    key={index}
                    title={row.section_heading}
                    description={row.section_sub_heading}
                    list={otherPeopleList}
                />
            );
        }

        // If image section
        if (contentType === 'WordPressAcf_image_single_section') {
            sectionContent = (
                <AtomFullImage
                    image={get(row, 'section_image')}
                />
            );
        }

        // If video section
        if (contentType === 'WordPressAcf_video_section') {
            sectionContent = (
                <AtomEmbedVideoContainer
                    key={index}
                    placeholder={get(row, 'video_poster')}
                    video={get(row, 'video_file')}
                    videoUrl={get(row, 'video_url')}
                    type={get(row, 'video_type')}
                />
            );
        }

        // If slider section
        if (contentType === 'WordPressAcf_slider_section') {
            sectionContent = (
                <AtomSlideContentItemcontainer
                    key={index}
                    list={get(row, 'slider_builder')}
                />
            );
        }

        // If testimonials section
        if (contentType === 'WordPressAcf_testimonial_listing') {
            sectionContent = (
                <AtomTestimonialContainer
                    key={index}
                    list={get(acfOptionsPageContent, 'testimonialList')}
                />
            );
        }

        // If projects/portfolio section
        if (contentType === 'WordPressAcf_project_listing') {

            sectionContent = (
                <AtomPortfolioFilters
                    categories={get(acfOptionsPageContent, 'projectCategoryList')}
                    items={get(acfOptionsPageContent, 'projectList')}
                    key={index}
                />
            );
        }

        // If work locations section
        if (contentType === 'WordPressAcf_work_location_listing') {

            // If not to show this section
            if (get(row, 'show_work_location') !== 'yes') {
                return;
            }

            // Default
            const workLocationItemsData = get(acfOptionsPageContent, 'workLocations');
            let workLocationItems = null;

            // Iterate data and format
            if (workLocationItemsData) {
                workLocationItems = map(workLocationItemsData, function (locationRow, locationIndex) {

                    // Return
                    return {
                        key: locationIndex,
                        title: get(locationRow, 'name'),
                        mapLatitude: toNumber(get(locationRow, 'latitude')),
                        mapLongitude: toNumber(get(locationRow, 'longitude')),
                        showMap: get(locationRow, 'enable_map') === 'yes',
                        listGroup: map(get(locationRow, 'section_rows'), function (locationItemRow, locationItemIndex) {

                            // Accessing fields
                            const address = get(locationItemRow, 'address');
                            const email = get(locationItemRow, 'email');
                            const isPhoneNumberAvailable = get(locationItemRow, 'is_phone_number_available');
                            const phoneNumber = get(locationItemRow, 'phone_number');
                            const phoneNumberText = get(locationItemRow, 'phone_number_text');

                            // Content
                            const locationItemContent = (
                                <p>
                                    {address && (
                                        <span>{get(locationItemRow, 'address')}</span>
                                    )}
                                    {email && (
                                        <span><a href={`mailto:${email}`}>{email}</a></span>
                                    )}
                                    {isPhoneNumberAvailable && (
                                        <span><a href={`tel:${phoneNumber}`}>{phoneNumberText}</a></span>
                                    )}
                                </p>
                            );

                            // Return
                            return {
                                key: locationItemIndex,
                                title: get(locationItemRow, 'section_heading'),
                                content: locationItemContent
                            };
                        })
                    };
                });
            }

            // Iterate data and format
            sectionContent = (
                <AtomContactContainer
                    items={workLocationItems}
                    key={index}
                />
            );
        }

        // If collapsible accordion section
        if (contentType === 'WordPressAcf_collapsible_accordion') {

            // Default
            const collapsibleContentItemsData = get(row, 'section_content.section_rows');
            let collapsibleContentItems = null;

            // Iterate data and format
            if (collapsibleContentItemsData) {
                collapsibleContentItems = map(collapsibleContentItemsData, function (collapsibleContentSectionRow, collapsibleContentSectionIndex) {

                    // Return
                    return {
                        key: collapsibleContentSectionIndex,
                        title: get(collapsibleContentSectionRow, 'section_heading'),
                        description: get(collapsibleContentSectionRow, 'section_sub_heading'),
                        listGroup: map(get(collapsibleContentSectionRow, 'collapsed_content'), function (collapsedContentRow, collapsedContentIndex) {

                            // Return
                            return {
                                key: collapsedContentIndex,
                                title: get(collapsedContentRow, 'section_content'),
                                content: get(collapsedContentRow, 'section_heading')
                            };
                        })
                    }
                });
            }

            sectionContent = (
                <AtomCollapsibleDetailContentContainer
                    items={collapsibleContentItems}
                    key={index}
                    title={get(row, 'section_title')}
                />
            );
        }


        // Return
        return PageContentObject(
            contentType,
            sectionContent,
            sectionData
        );
    });

    // If full-section content is present
    if (findFullSectionContent) {

        const fullSectionContentType = 'WordPressAcf_full_section_groups';

        // Get all content before full-section
        let preContent = null;
        const preDataContent = takeWhile(allContent, function (o) { return o.key !== fullSectionContentType });
        if (preDataContent) {
            preContent = map(preDataContent, 'content');
        }

        // Get all content after full-section
        let postContent = null;
        const postDataContent = takeRightWhile(allContent, function (o) { return o.key !== fullSectionContentType });
        if (postDataContent) {
            postContent = map(postDataContent, 'content');
        }

        // Return
        return (
            <AtomFullSection
                list={get(findFullSectionContent, 'groups')}
                postContent={postContent}
                preContent={preContent}
            />
        );
    }

    // allContent.push(PageContentObject(
    //     'test',
    //     // <AtomProjectCollection
    //     //     items={[

    //     //         {
    //     //             "name": "Australian Olympic Committee",
    //     //             "image": {
    //     //                 "localFile": {
    //     //                     "childImageSharp": {
    //     //                         "fluid": {
    //     //                             "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABbBajQKhcf//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhESAxAx/9oACAEBAAEFAshFbS6Qnc1//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/ARF//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/ATV//8QAGhAAAQUBAAAAAAAAAAAAAAAAEAABESExUf/aAAgBAQAGPwKO0sLD/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERITFhkfD/2gAIAQEAAT8htVboFBalCjJsRa92e8Fg/9oADAMBAAIAAwAAABBQD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQMBAT8QBqe3/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/EE4gy//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExYUFRcf/aAAgBAQABPxDgekLA7uALxpnQ+u7lwdCeXEmNAGO8ArhZz80zjz//2Q==",
    //     //                             "aspectRatio": 1.9302884615384615,
    //     //                             "src": "/static/9ff7e2318439179e7c910128cb9955ad/1bce9/aoc-work-image.jpg",
    //     //                             "srcSet": "/static/9ff7e2318439179e7c910128cb9955ad/01075/aoc-work-image.jpg 770w,\n/static/9ff7e2318439179e7c910128cb9955ad/1bce9/aoc-work-image.jpg 803w",
    //     //                             "sizes": "(max-width: 803px) 100vw, 803px"
    //     //                         }
    //     //                     }
    //     //                 },
    //     //                 "source_url": "https://sakui-website-cms.makealive.app/wp-content/uploads/2021/06/aoc-work-image.jpg"
    //     //             },
    //     //             "categories": [
    //     //                 "community",
    //     //                 "sport",
    //     //                 "tech"
    //     //             ],
    //     //             "url": {
    //     //                 "title": "View",
    //     //                 "url": "/work/australian-olympic-committee/",
    //     //                 "target": ""
    //     //             }
    //     //         },
    //     //         {
    //     //             "name": "Caringbah Super Centre",
    //     //             "image": {
    //     //                 "localFile": {
    //     //                     "childImageSharp": {
    //     //                         "fluid": {
    //     //                             "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAXvtSTjM3//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESA0L/2gAIAQEAAQUCxG8IjzhTR4R//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BJ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAAhESCh/9oACAEBAAY/AowYqf/EABkQAQEBAQEBAAAAAAAAAAAAAAERADFx4f/aAAgBAQABPyERB5ysOEAPu+smIsDIIEJgFhv/2gAMAwEAAgADAAAAELP/AP/EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAEDAQE/EIP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8QrH//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMWFxkf/aAAgBAQABPxBniHBUGuVznJsiha0c9YsoKf2+QKqwMIshxSZyGAAHgeE//9k=",
    //     //                             "aspectRatio": 1.9302884615384615,
    //     //                             "src": "/static/c96849e5bbb401b65a2222568114ba0f/1bce9/caringbah-work-banner.jpg",
    //     //                             "srcSet": "/static/c96849e5bbb401b65a2222568114ba0f/01075/caringbah-work-banner.jpg 770w,\n/static/c96849e5bbb401b65a2222568114ba0f/1bce9/caringbah-work-banner.jpg 803w",
    //     //                             "sizes": "(max-width: 803px) 100vw, 803px"
    //     //                         }
    //     //                     }
    //     //                 },
    //     //                 "source_url": "https://sakui-website-cms.makealive.app/wp-content/uploads/2021/06/caringbah-work-banner.jpg"
    //     //             },
    //     //             "categories": [
    //     //                 "art_and_design",
    //     //                 "retail",
    //     //                 "tech"
    //     //             ],
    //     //             "url": {
    //     //                 "title": "View",
    //     //                 "url": "/work/caringbah-super-centre/",
    //     //                 "target": ""
    //     //             }
    //     //         },
    //     //     ]}
    //     // />,
    //     []
    // ));

    // Return all content
    return map(allContent, 'content');
}

PageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
}
