// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// view
import AtomContactContent from '../ContactContent';

class AtomContactContainer extends React.Component {

  render() {
    const { items } = this.props;

    return (
      <div className="atom-contact-container">
        <div className="container">
          <AtomContactContent items={items} />
         </div>
      </div>
    )
  }
}

AtomContactContainer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      listGroup: PropTypes.array.isRequired,
      mapLatitude: PropTypes.number,
      mapLongitude: PropTypes.number,
      showMap: PropTypes.bool
    }),
  ).isRequired,
}

export default AtomContactContainer;

