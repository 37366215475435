// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// Utilities
import { size } from 'lodash';

// Packages
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class AtomSlider extends React.Component {

  render() {
    const { list, showArrows,showIndicators } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="atom-slider">
        <Carousel
          dynamicHeight
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          transitionTime={350}
          showArrows={showArrows}
          showIndicators={showIndicators}
        >
          {list}
        </Carousel>
      </div>
    )
  }
}
AtomSlider.defaultProps = {
  showArrows: true,
  showIndicators: false
}

AtomSlider.propTypes = {
  list: PropTypes.array.isRequired
};

export default AtomSlider;

