// Basics
import React from 'react';
import './index.scss';

// Utilities
import { get } from 'lodash';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import AtomEmbedVideo from '../EmbedVideo';

class AtomEmbedVideoContainer extends React.Component {

  render() {
    const { placeholder, type, video, videoUrl } = this.props;

    return (
      <div className="atom-embed-video-container">
        <div className="container">
          {type === 'file' && (
            <AtomEmbedVideo
              placeholder={get(placeholder, 'localFile.publicURL')}
              url={get(video, 'localFile.publicURL')}
            />
          )}
          {type === 'embed' && (
            <HtmlParser html={videoUrl} />
          )}
        </div>
       </div>
    );
  }
}

export default AtomEmbedVideoContainer;
