// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Views
import AtomCollapsibleDetailContent from '../CollapsibleDetailContent'

class AtomCollapsibleDetailContentContainer extends React.Component {

  render() {
    const {items, title} = this.props;

    return (
      <div className="atom-collapsible-detail-content-container">
        <h3>{title}</h3>
        <AtomCollapsibleDetailContent
          informationcontainerList={items}
        />
      </div>
    )
  }
}

AtomCollapsibleDetailContentContainer.defaultProps = {
  items: [
    {
      title: 'Strategy',
      description: 'Set your direction',
      listGroup: [
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        }
      ]
    },
    {
      title: 'Design',
      description: 'Reinvent your presence',
      listGroup: [
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
      ]
    },
    {
      title: 'Change',
      description: 'Realise your transformation ',
      listGroup: [
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
        {
          title: "Diagnosis, measurement, insight",
          content: "We identify opportunities you can uniquely own by analysing your business, your market, and cultural shifts in the world."
        },
      ]
    }
  ],
  title: 'We do three things'
}

AtomCollapsibleDetailContentContainer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      listGroup: PropTypes.array.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
}

export default AtomCollapsibleDetailContentContainer;

