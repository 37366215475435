// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Utilities
import {
  get,
  join,
  size
} from 'lodash';

// Assets
import AssetArrow from '../../../img/arrow-white.svg';

// Views
import AtomImage from '../Image';

class AtomProject extends React.Component {

  render() {
    const {
      categories,
      image,
      listClassName,
      title,
      url
    } = this.props;

    return (
      <a
        href={get(url, 'url')}
        target={get(url, 'target')}
      >
        <li className={`atom-project ${listClassName || ''} ${size(categories) > 0 ? join(categories, ' ') : ''}`}>
          <AtomImage
            source={image}
            type="fluid"
          />
          <div className="caption">
            <h3>{title}</h3>
            <img src={AssetArrow} alt="Arrow" className="arrow" />
          </div>
        </li>
      </a>
    );
  }
}

AtomProject.propTypes = {
  categories: PropTypes.array,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  listClassName: PropTypes.string,
  url: PropTypes.shape({
    target: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
}

export default AtomProject;