// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// Utilities
import {
  get,
  includes
} from 'lodash';

// Views
import AtomImage from '../Image';

class AtomSlideContentItem extends React.Component {

  render() {
    const { description, image, layoutType, title } = this.props;
    return (
      <div className={`atom-slide-content-item ${layoutType}`}>
        {
          includes(['right-image', 'left-image', 'only-image'], layoutType) && (
            <div className="slide-image">
              <AtomImage
                source={get(image, 'localFile.childImageSharp.fluid')}
                type="fluid"
              />
            </div>
          )
        }
        <div className="slide-content">
          {
            title &&
            <h2>{title}</h2>
          }
          {description}
        </div>

      </div>
    )
  }
}

AtomSlideContentItem.propTypes = {
  description: PropTypes.object,
  image: PropTypes.object,
  layoutType: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default AtomSlideContentItem;

