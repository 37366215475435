// Basics
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import {
  get,
  map,
  size
} from 'lodash';

// Views
import AtomProject from './index';

class AtomProjectCollection extends React.Component {

  render() {
    const {
      className,
      items
    } = this.props;

    // If items is empty
    if (size(items) < 1) {
      return null;
    }

    return (
      <ul className={`atom-project-collection ${className || ''}`}>
        {
          map(items, (row, index) => {

            return (
              <AtomProject
                categories={get(row, 'categories')}
                key={index}
                image={get(row, 'image.localFile.childImageSharp.fluid')}
                listClassName="filter-item"
                title={row.name}
                url={get(row, 'url')}
              />
            );
          })
        }
      </ul>
    );
  }
}

AtomProjectCollection.propTypes = {
  className: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    categories: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    url: PropTypes.shape({
      target: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
  })).isRequired,}

export default AtomProjectCollection;