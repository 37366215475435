// Basics
import React from 'react'
import './index.scss';
import PropTypes from 'prop-types';

import { map, size } from 'lodash';

class AtomGridContainer extends React.Component {
  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="atom-grid-container">
        <div className="columns">
          {
            map(list, function (row, index) {
              const { title, content } = row;
              return (
                <div className="column" key={index}>
                  <div className="grid-content">
                    {
                      title &&
                      <h4>{title}</h4>
                    }
                    {
                      content &&
                      <div className="grid-description">{content}</div>
                    }

                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
    )
  }
}

AtomGridContainer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
  ).isRequired,
}

export default AtomGridContainer;

