// Basics
import React from 'react'
import './index.scss';
import PropTypes from 'prop-types';


class AtomSection extends React.Component {

  render() {
    const { content, title, description } = this.props;

    return (
      <div className="atom-section">
        <div className="container">
          {
            title &&
            <h4>{title}</h4>
          }
          {
            description &&
            <p>{description}</p>
          }
          <div className="section-content">{content}</div>
        </div>
      </div>
    )
  }
}

AtomSection.propTypes = {
  content: PropTypes.element,
  description: PropTypes.string,
  title: PropTypes.string,
}

export default AtomSection;

