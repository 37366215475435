// Basics
import React from 'react';
import "video-react/dist/video-react.css"; // import css
import './index.scss';
import PropTypes from 'prop-types';
import { Player, LoadingSpinner, BigPlayButton, ControlBar } from 'video-react';


class AtomEmbedVideo extends React.Component {

  render() {
    const { placeholder, url } = this.props;

    return (
      <div className="atom-embed-video">
        <Player
          playsInline
          src={url}
          poster={placeholder}
        >
          <LoadingSpinner />
          <BigPlayButton position="center" />
          <ControlBar
            autoHide
          />
        </Player>
      </div>
    );
  }
}

AtomEmbedVideo.propTypes = {
  url: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default AtomEmbedVideo;