// Basics
import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";

const AtomImage = ({ type, source }) => {

  if (type === 'fluid') {
    return (
      <Img fluid={source} />
    );
  }
  
  return (
    <Img fixed={source} />
  );
}

AtomImage.propTypes = {
  type: PropTypes.oneOf(['fluid', 'fixed']).isRequired,
  source: PropTypes.object.isRequired,
};

export default AtomImage;
