// Basics
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './index.scss';

// Utilities
import { get, map, size } from 'lodash';

// Views
import AtomImage from '../Image';

class AtomOurPeopleContent extends React.Component {

  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="atom-out-people-content">
        {
          map(list, function (row, index) {
            const { designation, image, item, title } = row;

            return (
              <div
                className="our-people-column"
                key={index}
              >
                <div className="image">
                  <AtomImage
                    source={get(image, 'localFile.childImageSharp.fluid')}
                    type="fluid"
                  />
                  {size(item) > 0 && (
                    <div className="social">
                      <ul>
                        {
                          map(item, function (itemrow, itemindex) {
                            const { link, icon } = itemrow;
                            return (
                              <li key={itemindex}>
                                <a
                                  href={link}
                                >
                                  <i className={`fa fa-${icon}`} />
                                </a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  )}
                </div>
                <h6>{title}</h6>
                <p>{designation}</p>
              </div>
            )
          })
        }
      </div>
    )
  }
}

AtomOurPeopleContent.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    designation: PropTypes.string.isRequired,
    image: PropTypes.string.object,
    item: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })).isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
}

export default AtomOurPeopleContent;

