// Basics
import React from 'react'
import PropTypes from 'prop-types';
import './index.scss';

// Utilities
import { get } from 'lodash';

// Views
import AtomImage from '../Image';


class AtomFullImage extends React.Component {

  render() {
    const { image } = this.props;
    return (
      <div className="atom-full-image">
        <div className="container">
          <AtomImage
            source={get(image, 'localFile.childImageSharp.fluid')}
            type="fluid"
          />
        </div>
 
      </div>
    )
  }
}

 

export default AtomFullImage;

