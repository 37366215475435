// Utilities
import {get, map, size} from 'lodash';

// Update array key by id
export function updateArrayKey(arrayData, key = 'id') {

    // If array is empty
    if (size(arrayData) < 1) {
        return arrayData;
    }

    // Default
    const preparedArray = {};

    // Iterate array
    map(arrayData, function (row) {

        const rowId = get(row, key);
        if (rowId) {
            preparedArray[rowId] = row;
        }
    });

    // Return
    return preparedArray;
}

// Normalized data object
export const normalizedDataObject = (byId = null, allIds = null) => {

    // Return
    return {
        byId: byId,
        allIds: allIds
    };
};

// Normalize array
export const normalizeData = (array, updateById = false, primaryKey = 'section_key') => {

    // If array is empty
    if (size(array) < 1) {
        return normalizedDataObject();
    }

    // Return
    return normalizedDataObject(
        (updateById ? updateArrayKey(array, primaryKey) : null),
        getDataCollectionIds(array, primaryKey)
    );
};

// Get all Ids of data collection
export const getDataCollectionIds = (array, primaryKey = 'id') => {

    // If array is empty
    if (size(array) < 1) {
        return null;
    }

    // Return
    return map(array, function(row) {
        return get(row, primaryKey);
    });
};
